// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC7OUFzropVXy3UsK5b4NQX-6a0FarRgn0",
    authDomain: "independent-productions.firebaseapp.com",
    projectId: "independent-productions",
    storageBucket: "independent-productions.appspot.com",
    messagingSenderId: "108106608476",
    appId: "1:108106608476:web:d6c76c1a7cbd0a760e9b99",
    measurementId: "G-6TQ30R6WQM"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
